import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

// Fetch songs and charts data
export const fetchSongsAndCharts = async () => {
  try {
    // Fetch songs data
    const songsSnapshot = await getDocs(collection(db, 'Songs'));
    const songsData = songsSnapshot.docs.map((doc, index) => ({ id: index + 1, docId: doc.id, ...doc.data() }));
    
    // Fetch charts data
    const chartsSnapshot = await getDocs(collection(db, 'Charts'));
    const chartsData = chartsSnapshot.docs.map(doc => doc.data());

    // Count the number of weeks each song is on the chart and track peak position
    const weeksOnChartMap = {};
    const peakPositionMap = {};

    chartsData.forEach(chart => {
      if (chart.songs && Array.isArray(chart.songs)) {
        chart.songs.forEach(song => {
          // Track weeks on chart
          if (weeksOnChartMap[song.songTitle]) {
            weeksOnChartMap[song.songTitle] += 1;
          } else {
            weeksOnChartMap[song.songTitle] = 1;
          }

          // Track peak position
          if (peakPositionMap[song.songTitle]) {
            if (song.position < peakPositionMap[song.songTitle]) {
              peakPositionMap[song.songTitle] = song.position;
            }
          } else {
            peakPositionMap[song.songTitle] = song.position;
          }
        });
      }
    });

    // Merge songs data with weeks on chart and peak position data
    const mergedData = songsData.map(song => ({
      ...song,
      no_of_weeks: weeksOnChartMap[song.title] || 0,
      peakPos: peakPositionMap[song.title] || null,
    }));

    // Sort mergedData alphabetically by title
    mergedData.sort((a, b) => a.title.localeCompare(b.title));

    // Calculate total number of songs
    const totalSongs = songsData.length;

    return { mergedData, totalSongs };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};


// Delete song by document ID
export const deleteSong = async (docId) => {
  try {
    await deleteDoc(doc(db, 'Songs', docId));
  } catch (error) {
    throw error;
  }
};
