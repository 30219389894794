import { collection, query, where, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

// Function to fetch charts data from Firestore
export const fetchChartsData = async () => {
  try {
    const chartsSnapshot = await getDocs(collection(db, 'Charts'));
    const chartsData = chartsSnapshot.docs.map((doc, index) => ({
      id: index + 1,
      docId: doc.id,
      ...doc.data(),
    }));

    // Sort charts by date created (newest first)
    chartsData.sort((a, b) => new Date(b.date) - new Date(a.date));

    return chartsData;
  } catch (error) {
    throw error;
  }
};

// Function to fetch the total count of charts
export const fetchChartsCount = async () => {
  try {
    const chartsSnapshot = await getDocs(collection(db, 'Charts'));
    return chartsSnapshot.size;
  } catch (error) {
    throw error;
  }
};

// Function to delete a chart from Firestore
export const deleteChart = async (docId) => {
  try {
    await deleteDoc(doc(db, 'Charts', docId));
  } catch (error) {
    throw error;
  }
};

// Function to fetch admin's phone number from Firestore
export const fetchAdminPhoneNumber = async (email) => {
  try {
    const adminCollection = collection(db, 'admin');
    const q = query(adminCollection, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const adminDoc = querySnapshot.docs[0]; // Assuming there's only one document per email
      const phone = adminDoc.data().phone;
      return phone;
    } else {
      throw new Error('Admin not found');
    }
  } catch (error) {
   // console.error('Error fetching admin phone number:', error.message);
    throw error;
  }
};

/*import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

// Function to fetch charts data from Firestore
export const fetchChartsData = async () => {
  try {
    const chartsSnapshot = await getDocs(collection(db, 'Charts'));
    const chartsData = chartsSnapshot.docs.map((doc, index) => ({
      id: index + 1,
      docId: doc.id,
      ...doc.data(),
    }));

    // Sort charts by date created (newest first)
    chartsData.sort((a, b) => new Date(b.date) - new Date(a.date));

    return chartsData;
  } catch (error) {
    throw error;
  }
};

// Function to fetch the total count of charts
export const fetchChartsCount = async () => {
    try {
      const chartsSnapshot = await getDocs(collection(db, 'Charts'));
      return chartsSnapshot.size;
    } catch (error) {
      throw error;
    }
  };

// Function to delete a chart from Firestore
export const deleteChart = async (docId) => {
  try {
    await deleteDoc(doc(db, 'Charts', docId));
  } catch (error) {
    throw error;
  }
};
*/