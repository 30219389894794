import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

export const fetchUserData = async () => {
  try {
    // Fetch data from the users collection
    const usersSnapshot = await getDocs(collection(db, 'users'));
    const usersData = usersSnapshot.docs.map(doc => doc.data());

    // Count number of users
    const numberOfUsers = usersData.length;
  
    // Fetch users' profiles
    const userProfiles = usersData.map(user => ({
      id: user.id,
      email: user.email,
      username: user.username,
     // profilePicture: user.profilePicture,
      dateRegistered: user.dateRegistered,
    }));


    return {
      numberOfUsers,
      userProfiles,
    };

  } catch (error) {
    throw error;
  }
};
