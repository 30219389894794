import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import React, { useEffect, useState } from 'react';
import { Box, useTheme, CircularProgress, InputBase, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Alert, Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import LyricsIcon from '@mui/icons-material/Lyrics';
import { useNavigate } from 'react-router-dom';
import { fetchAds, deleteAd } from '../../api/firestore/adsData';

// Update ad status in Firestore
const updateAdStatus = async (docId, newStatus) => {
  try {
    const adRef = doc(db, 'Ads', docId);
    await updateDoc(adRef, { status: newStatus });
  } catch (error) {
    throw error;
  }
};

const Ads = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [ads, setAds] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [adToDelete, setAdToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { ads } = await fetchAds();
        
        // Ensure each item has a unique 'id' field
        const dataWithId = ads.map(ad => ({
          ...ad,
          id: ad.docId // Ensure 'id' is unique
        }));
        
        setAds(dataWithId);
        setFilteredAds(dataWithId);
      } catch (error) {
        setErrorMessage('Failed to fetch ads');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = ads.filter(ad =>
      ad.title.toLowerCase().includes(query) ||
      ad.source.toLowerCase().includes(query) ||
      ad.type.toLowerCase().includes(query) ||
      ad.admin.toLowerCase().includes(query)
    );

    setFilteredAds(filtered);
  };

  const handleOpenDialog = (ad) => {
    setAdToDelete(ad);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAdToDelete(null);
  };

  const handleDelete = async () => {
    try {
      if (adToDelete) {
        await deleteAd(adToDelete.docId);

        const updatedAds = ads.filter(ad => ad.docId !== adToDelete.docId);
        setAds(updatedAds);
        setFilteredAds(updatedAds);

        setSuccessMessage('Ad deleted successfully');
      }
    } catch (error) {
      setErrorMessage('Failed to delete ad');
    } finally {
      handleCloseDialog();
      setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 6000);
    }
  };

  const handleStatusChange = async (ad) => {
    const newStatus = ad.status === 'running' ? 'not running' : 'running';
    try {
      await updateAdStatus(ad.docId, newStatus);
      const updatedAds = ads.map(a => a.docId === ad.docId ? { ...a, status: newStatus } : a);
      setAds(updatedAds);
      setFilteredAds(updatedAds);
    } catch (error) {
      setErrorMessage('Failed to update ad status');
      setTimeout(() => {
        setErrorMessage('');
      }, 6000);
    }
  };

  const columns = [
    { field: 'title', headerName: 'Title', flex: 1, cellClassName: 'name-column--cell' },
    { field: 'source', headerName: 'Source', headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'type', headerName: 'Type', type: 'string', flex: 1 },
    { field: 'adUrl', headerName: 'Ad Url', type: 'string', flex: 1 },
    { field: 'admin', headerName: 'Admin', type: 'string', flex: 1 },
    { field: 'date', headerName: 'Date Created', type: 'string', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={params.row.status === 'running'}
          onChange={() => handleStatusChange(params.row)}
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: 'green',
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: 'green',
            },
            '& .MuiSwitch-switchBase': {
              color: 'black',
            },
            '& .MuiSwitch-track': {
              backgroundColor: 'black',
            },
          }}
        />
      ),
    },   
    {
      field: 'actions',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenDialog(params.row)} color="error">
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box m="15px">
      <Helmet>
        <title>Ads | Cubana Chart</title>
      </Helmet>
      <Header title="ADS" subtitle="List of Ads" sx={{ color: 'red' }} />
      <Box borderRadius="3px" sx={{ border: '1px solid #ccc' }} padding="10px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="15px">
          <Box
            display="flex"
            backgroundColor={'#e0e0e0'}
            borderRadius="3px"
            border={'1px solid #6c757d'}
            sx={{ width: '300px' }}
          >
            <InputBase
              sx={{ ml: 3, flex: 1, color: "#000" }}
              placeholder="Search Ads"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<LyricsIcon />}
            onClick={() => navigate('/new_ad_form')}
            sx={{ ml: 2 }}
          >
            Create New Ad
          </Button>
        </Box>
        <Box
          m="30px 0 0 0"
          height="75vh"
          sx={{
            '& .MuiDataGrid-root': { border: 'none' },
            '& .MuiDataGrid-cell': { borderBottom: 'none', color: colors.black[300] },
            '& .name-column--cell': { color: colors.black[300] },
            '& .MuiDataGrid-columnHeaders': { backgroundColor: colors.grey[200], borderBottom: 'none' },
            '& .MuiDataGrid-virtualScroller': { backgroundColor: colors.grey[100] },
            '& .MuiDataGrid-footerContainer': { borderTop: 'none', backgroundColor: colors.grey[500] },
          }}
        >
          {successMessage && <Alert severity="success" onClose={() => setSuccessMessage('')}>{successMessage}</Alert>}
          {errorMessage && <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>}
          <DataGrid
            rows={filteredAds}
            columns={columns}
            loading={loading}
            getRowId={(row) => row.docId} 
            components={{
              NoRowsOverlay: () => (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  {loading ? <CircularProgress /> : "No ads"}
                </Box>
              ),
            }}
          />
        </Box>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this ad?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Ads;
