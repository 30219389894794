import React, { useEffect, useState } from 'react';
import { Box, useTheme, CircularProgress, InputBase } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { Helmet } from 'react-helmet';
import { fetchArtistsData } from '../../api/firestore/artistData';

const Artists = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [artists, setArtists] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredArtists, setFilteredArtists] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const artistsArray = await fetchArtistsData();
        setArtists(artistsArray);
        setFilteredArtists(artistsArray);
      } catch (error) {
        console.error('Failed to fetch artists:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = artists.filter(artist =>
      artist.name.toLowerCase().includes(query) ||
      artist.country.toLowerCase().includes(query)
    );

    setFilteredArtists(filtered);
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, cellClassName: 'name-column--cell' },
    { field: 'no_of_songs', headerName: 'Songs', type: 'number', headerAlign: 'left', align: 'left' },
    { field: 'no_of_songs_on_chart', headerName: 'No. of Songs On Chart', type: 'number', headerAlign: 'left', align: 'left' },
    { field: 'no_of_no_1_songs_on_chart', headerName: 'No. of No 1 Songs On Chart', type: 'number', headerAlign: 'left', align: 'left' },
    { field: 'country', headerName: 'Country', flex: 1 },
  ];

  return (
    <Box m="15px">
      <Helmet>
        <title>Artists | Cubana Chart</title>
      </Helmet>
      <Header title="ARTISTS" subtitle="List of Artists" />
      <Box borderRadius="3px" sx={{ border: '1px solid #ccc' }} padding="10px">
        <Box
          display="flex"
          backgroundColor={'#e0e0e0'}
          borderRadius="3px"
          border={'1px solid #6c757d'}
          sx={{ width: '300px' }}
        >
          <InputBase
            sx={{ ml: 3, flex: 1, color: "#000" }}
            placeholder="Search Artists"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Box>
        <Box
          m="30px 0 0 0"
          height="75vh"
          sx={{
            '& .MuiDataGrid-root': { border: 'none' },
            '& .MuiDataGrid-cell': { borderBottom: 'none', color: colors.black[300] },
            '& .name-column--cell': { color: colors.black[300] },
            '& .MuiDataGrid-columnHeaders': { backgroundColor: colors.grey[200], borderBottom: 'none' },
            '& .MuiDataGrid-virtualScroller': { backgroundColor: colors.grey[100] },
            '& .MuiDataGrid-footerContainer': { borderTop: 'none', backgroundColor: colors.grey[500] },
          }}
        >
          <DataGrid
            rows={filteredArtists}
            columns={columns}
            loading={loading}
            components={{
              NoRowsOverlay: () => (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  {loading ? <CircularProgress /> : "No artists"}
                </Box>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Artists;
