import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC3D7Ge8VmuOA_P4AYDy6nxoVfc6mRfngQ",
  authDomain: "cubana-chart-5bace.firebaseapp.com",
  projectId: "cubana-chart-5bace",
  storageBucket: "cubana-chart-5bace.appspot.com",
  messagingSenderId: "814107937526",
  appId: "1:814107937526:web:ce67ec2dd292d18c08bad7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

export { db, auth };
export default app;

