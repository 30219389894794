import React, { useState } from 'react';
import { Box, Button, TextField, Typography, useTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import SendIcon from '@mui/icons-material/Send';
import Header from '../../components/Header';
import { Helmet } from 'react-helmet';
import { db } from '../../firebase';
import { collection, doc, setDoc } from 'firebase/firestore';
import Select from 'react-select';
import { tokens } from '../../theme';
import { useUserAuth } from '../../context/UserAuthContext';

const initialValues = {
  name: '',
  email: '',
  phone: '',
  password: '',
  role: { value: 'admin', label: 'Admin' },
};

const adminSchema = yup.object().shape({
  name: yup.string().required('required'),
  email: yup.string().required('required').email('invalid email'),
  phone: yup.string().required('required'),
  password: yup.string().required('required').min(6, 'Password should be at least 6 characters'),
  role: yup.object().required('required').nullable(),
});

const capitalize = (text) => {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
};

const New_Admin_Form = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const { signUp } = useUserAuth();

  const handleClose = () => {
    setOpenModal(false);
  };

  const formSubmitHandler = async (values, { resetForm }) => {
    try {
      const { email, password } = values;
      const userCredential = await signUp(email, password);
      const user = userCredential.user;

      const formattedValues = {
        id: user.uid,
        name: capitalize(values.name),
        email: values.email,
        phone: values.phone,
        role: values.role.label,
      };

      await setDoc(doc(db, 'admin', user.uid), formattedValues);
      setSuccessMessage('Admin added successfully');
      setErrorMessage('');
      setOpenModal(true);
      resetForm();
    } catch (e) {
      const errorMessage = e.code === 'auth/email-already-in-use' ? 'Email already in use' : `Error adding admin: ${e.message}`;
      setErrorMessage(errorMessage);
      setSuccessMessage('');
      setOpenModal(true);
    }
  };

  return (
    <Box m="15px">
      <Helmet>
        <title>Add New Admin | Cubana Admin</title>
      </Helmet>
      <Header title="ADD NEW ADMIN" subtitle="Add a New Admin" />

      <Formik
        onSubmit={formSubmitHandler}
        initialValues={initialValues}
        validationSchema={adminSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              backgroundColor="#fff"
              border="1px solid #ccc"
              padding="20px"
              borderRadius="5px"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Admin Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{
                  gridColumn: 'span 2',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{
                  gridColumn: 'span 2',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{
                  gridColumn: 'span 2',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{
                  gridColumn: 'span 2',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <Box sx={{ gridColumn: 'span 4', backgroundColor: colors.red, borderRadius: '4px' }}>
                <Typography variant="body1" sx={{ fontSize: 'small', color: 'black', marginBottom: '1px' }}>
                  Role
                </Typography>
                <Select
                  options={[
                    { value: 'admin', label: 'Admin' },
                    { value: 'super-admin', label: 'Super Admin' },
                  ]}
                  value={values.role}
                  onChange={option => setFieldValue('role', option)}
                  onBlur={handleBlur}
                  name="role"
                  styles={{
                    control: (base) => ({
                      ...base,
                      backgroundColor: colors.red,
                      borderRadius: '0px',
                      borderColor: errors.role && touched.role ? 'red' : base.borderColor,
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '4px',
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? 'white' : colors.grey[400],
                      color: 'black',
                    }),
                  }}
                />
                {touched.role && errors.role && (
                  <div style={{ color: 'red', marginTop: '5px' }}>{errors.role}</div>
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="15px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SendIcon />}
              >
                Add Admin
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Dialog
        open={openModal}
        onClose={handleClose}
      >
        <DialogTitle>{successMessage ? 'Success' : 'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: '#fff' }}>
            {successMessage || errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ backgroundColor: '#3ed791' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default New_Admin_Form;
