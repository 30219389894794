import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

export const fetchArtistsData = async () => {
  try {
    // Fetch data from the Songs collection
    const songsSnapshot = await getDocs(collection(db, 'Songs'));
    const songsData = songsSnapshot.docs.map(doc => doc.data());

    // Fetch data from the Charts collection
    const chartSnapshot = await getDocs(collection(db, 'Charts'));
    const chartData = chartSnapshot.docs.map(doc => doc.data());

    const artistMap = songsData.reduce((acc, song) => {
      const { artiste, country } = song;
      const mainArtist = artiste.split(' ft. ')[0];
      if (!acc[mainArtist]) {
        acc[mainArtist] = {
          name: mainArtist,
          no_of_songs: 0,
          no_of_songs_on_chart: 0,
          no_of_no_1_songs_on_chart: 0,
          country: country || 'Unknown',
        };
      }
      acc[mainArtist].no_of_songs += 1;
      return acc;
    }, {});

    // Calculate the number of No. 1 songs and total songs on charts for each artist
    chartData.forEach(chart => {
      if (chart.songs && Array.isArray(chart.songs)) {
        chart.songs.forEach(song => {
          const { artiste, position } = song;
          const mainArtist = artiste.split(' ft. ')[0];
          if (artistMap[mainArtist]) {
            artistMap[mainArtist].no_of_songs_on_chart += 1;
            if (position === 1) {
              artistMap[mainArtist].no_of_no_1_songs_on_chart += 1;
            }
          }
        });
      }
    });

    const artistsArray = Object.values(artistMap).map((artist, index) => ({
      id: index + 1,
      ...artist
    }));

    // Sort the artists alphabetically by name
    artistsArray.sort((a, b) => a.name.localeCompare(b.name));

    return artistsArray;
  } catch (error) {
    throw error;
  }
};
