import React, { useEffect, useState } from 'react';
import {
  Box,
  useTheme,
  CircularProgress,
  InputBase,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Alert,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { fetchAdminsAndCharts, deleteAdmin } from '../../api/firestore/adminData';

const Admin = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [admins, setAdmins] = useState([]);
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminsData = await fetchAdminsAndCharts();

        // Ensure each item has a unique 'id' field
        const dataWithId = adminsData.map((admin) => ({
          ...admin,
          id: admin.docId, // Ensure 'id' is unique
        }));

        setAdmins(dataWithId);
        setFilteredAdmins(dataWithId);
      } catch (error) {
        setErrorMessage('Failed to fetch admins');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = admins.filter(
      (admin) =>
        admin.name.toLowerCase().includes(query) ||
        admin.email.toLowerCase().includes(query)
    );

    setFilteredAdmins(filtered);
  };

  const handleOpenDialog = (admin) => {
    setAdminToDelete(admin);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAdminToDelete(null);
  };

  const handleDelete = async () => {
    try {
      if (adminToDelete && adminToDelete.role !== 'Site Admin') {
        await deleteAdmin(adminToDelete.docId);

        const updatedAdmins = admins.filter(
          (admin) => admin.docId !== adminToDelete.docId
        );
        setAdmins(updatedAdmins);
        setFilteredAdmins(updatedAdmins);

        setSuccessMessage('Admin deleted successfully');
      }
    } catch (error) {
      setErrorMessage('Failed to delete admin');
    } finally {
      handleCloseDialog();
      setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 6000);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, cellClassName: 'name-column--cell' },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'chartCount', headerName: 'Charts Posted', type: 'number', flex: 0.5 },
    { field: 'role', headerName: 'Role', flex: 1 },
    {
      field: 'actions',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => (
        params.row.role !== 'Site Admin' && (
          <IconButton onClick={() => handleOpenDialog(params.row)} color="error">
            <DeleteIcon />
          </IconButton>
        )
      ),
    },
  ];

  return (
    <Box m="15px">
      <Helmet>
        <title>Admins | Cubana Chart</title>
      </Helmet>
      <Header title="ADMINS" subtitle="List of Admins" />
      <Box borderRadius="3px" sx={{ border: '1px solid #ccc' }} padding="10px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="15px">
          <Box
            display="flex"
            backgroundColor={'#e0e0e0'}
            borderRadius="3px"
            border={'1px solid #6c757d'}
            sx={{ width: '300px' }}
          >
            <InputBase
              sx={{ ml: 3, flex: 1, color: '#000' }}
              placeholder="Search Admins"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/new_admin_form')}
            sx={{ ml: 2 }}
          >
            Add New Admin
          </Button>
        </Box>
        <Box
          m="30px 0 0 0"
          height="75vh"
          sx={{
            '& .MuiDataGrid-root': { border: 'none' },
            '& .MuiDataGrid-cell': { borderBottom: 'none', color: colors.black[300] },
            '& .name-column--cell': { color: colors.black[300] },
            '& .MuiDataGrid-columnHeaders': { backgroundColor: colors.grey[200], borderBottom: 'none' },
            '& .MuiDataGrid-virtualScroller': { backgroundColor: colors.grey[100] },
            '& .MuiDataGrid-footerContainer': { borderTop: 'none', backgroundColor: colors.grey[500] },
          }}
        >
          {successMessage && (
            <Alert severity="success" onClose={() => setSuccessMessage('')}>
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error" onClose={() => setErrorMessage('')}>
              {errorMessage}
            </Alert>
          )}
          <DataGrid
            rows={filteredAdmins}
            columns={columns}
            loading={loading}
            getRowId={(row) => row.docId} // Specify the custom ID field
            components={{
              NoRowsOverlay: () => (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  {loading ? <CircularProgress /> : 'No admins'}
                </Box>
              ),
            }}
          />
        </Box>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this admin?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Admin;
