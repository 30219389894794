import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import Dashboard from './scenes/dashboard';
import Sidebar from './scenes/global/Sidebar';
import Artists from './scenes/artists';
import Songs from './scenes/songs';
import Charts from './scenes/charts'
import Admin from './scenes/admin';
import Ads from './scenes/ads';
import New_Chart_Form from './scenes/new_chart_form';
import PreviewChart from './scenes/new_chart_form/PreviewChart';
import New_Song_Form from './scenes/new_song_form';
import New_Admin_Form from './scenes/new_admin_form';
import New_Ad_Form from './scenes/new_ad_form';
import Calendar from './scenes/calendar/';
import SAD from './scenes/contact_site_admin';
import Login from './components/auth/Login';
import Preloader from './components/Loader'; 
import { Container, Row, Col } from "react-bootstrap";
import { UserAuthContextProvider, useUserAuth } from './context/UserAuthContext';
import PrivateRoute from "./context/PrivateRoute";
import Unauthorized from './components/auth/Unauthorized';
import "./App.css";

function App() {
  const [theme, colorMode] = useMode();
  const { user } = useUserAuth();
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const handleNavigation = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 700); 
    };

    handleNavigation();

    return () => {
      setLoading(false);
    };
  }, [location]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
       
          {!user ? (
            <Login />
          ) : (
            <>
             <div className="dashboard">
              <Sidebar currentPath={location.pathname} />
              <main className="content">  
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/artists" element={<Artists />} />
                  <Route path="/songs" element={<Songs />} />
                  <Route path="/charts" element={<Charts />} />
                  <Route path="/new_chart_form" element={<New_Chart_Form />} />
                  <Route path="/new_song_form" element={<New_Song_Form />} />
                  <Route path="/add_new_admin" element={<New_Admin_Form />} />
                  <Route path="/create_new_ad" element={<New_Ad_Form />} />
                  <Route path="/preview" element={<PreviewChart />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/contact_site_admin" element={<SAD />} />
                  <Route path="/unauthorized" element={<Unauthorized />} />

                  {/* Protected Routes for Admin and Super Admin */}
                  <Route element={<PrivateRoute allowedRoles={['Super Admin', 'Site Admin']} />}>
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/ads" element={<Ads />} />
                    <Route path="/add_new_admin" element={<New_Admin_Form />} />
                    <Route path="/create_new_ad" element={<New_Ad_Form />} />
                  </Route>
                </Routes>
              </main>
              </div>
            </>
          )}
     
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default function AppWrapper() {
  return (
    <Container>
      <Row>
        <Col>
          <UserAuthContextProvider>
            <Router>
              <App />
            </Router>
          </UserAuthContextProvider>
        </Col>
      </Row>
    </Container>
  );
}
