import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, InputGroup, Button } from "react-bootstrap";
import { useUserAuth } from "../../context/UserAuthContext";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const adminRef = collection(db, "admin");
      const q = query(adminRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        setError("You do not have access to this system.");
        return;
      }
      await logIn(email, password);
      navigate("/"); 
    } catch (err) {
      switch (err.code) {
        case "auth/too-many-requests":
          setError("Access to this account has been temporarily disabled due to many failed login attempts. Please try again later or reset your password.");
          break;
        case "auth/invalid-credential":
          setError("Incorrect Emaail or Password. Please try again.");
          break;
        case "auth/wrong-password":
          setError("Wrong password. Please try again.");
          break;
        case "auth/network-request-failed":
          setError("Network Error. Please try again.");
          break;
        default:
          setError(err.message);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="" >
        <div className="p-4 box">
          <img src="/logo.png" alt="cubana chat" width={50} height={50} style={{ display: 'block', margin: '0 auto' }} />
          <h2 className="mb-3" style={{ color: 'black' }}>Cubana Chart Admin Login</h2>

          {error && <Alert variant="danger">{error}</Alert>}

          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputGroup.Text>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                    sx={{ color: 'black' }} 
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <div className="d-grid gap-2">
              <Button variant="primary" type="submit">
                Log In
              </Button>
            </div>
          </Form>
          <hr style={{ color: 'black' }} />
          <span className="mb-3" style={{ color: 'black' }}>Forgot Password? Pls The Contact Site Admin</span>
        </div>
      </div>
    </>
  );
};

export default Login;
