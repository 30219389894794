import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import InputBase from '@mui/material/InputBase';
import AddchartIcon from '@mui/icons-material/Addchart';
import { fetchChartsData, deleteChart, fetchAdminPhoneNumber } from '../../api/firestore/chartData';
import { useUserAuth } from '../../context/UserAuthContext';

const Charts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [charts, setCharts] = useState([]);
  const [filteredCharts, setFilteredCharts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [chartToDelete, setChartToDelete] = useState(null);
  const [phoneInput, setPhoneInput] = useState('');
  const [adminPhoneNumber, setAdminPhoneNumber] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [dialogErrorMessage, setDialogErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { user } = useUserAuth();
  const email = user.email;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const chartsData = await fetchChartsData();
        setCharts(chartsData);
        setFilteredCharts(chartsData);
  
        const adminPhoneNumber = await fetchAdminPhoneNumber(email);
        setAdminPhoneNumber(adminPhoneNumber);
      } catch (error) {
        console.error('Error fetching data:', error.message);
        setErrorMessage(`Failed to fetch data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [email]);
  

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = charts.filter(chart => {
      const chartTitle = chart.chartTitle?.toLowerCase() || '';
      const creator = chart.creator?.toLowerCase() || '';
      const date = chart.date?.toLowerCase() || '';
      const songMatch = chart.songs?.some(song =>
        song.songTitle?.toLowerCase().includes(query) ||
        song.artiste?.toLowerCase().includes(query)
      );

      return chartTitle.includes(query) || creator.includes(query) || date.includes(query) || songMatch;
    });

    setFilteredCharts(filtered);
  };

  const handleOpenDialog = (chart) => {
    setChartToDelete(chart);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setChartToDelete(null);
    setPhoneInput('');
    setDialogErrorMessage('');
  };

  const handleDelete = async () => {
    if (phoneInput !== adminPhoneNumber) {
      setDialogErrorMessage('Phone number does not match');
      return;
    }

    try {
      if (chartToDelete) {
        await deleteChart(chartToDelete.docId);

        const updatedCharts = charts.filter(chart => chart.docId !== chartToDelete.docId);
        setCharts(updatedCharts);
        setFilteredCharts(updatedCharts);

        setSuccessMessage('Chart deleted successfully');
      }
    } catch (error) {
      setErrorMessage('Error deleting chart');
    } finally {
      handleCloseDialog();
      setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 6000);
    }
  };

  const columns = [
    {
      field: 'chartTitle',
      headerName: 'Title',
      flex: 0.5,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'songs',
      headerName: 'Chart List',
      flex: 2,
      renderCell: (params) => (
        <Box>
          {params.row.songs && params.row.songs.length > 0
            ? params.row.songs.map((song, index) => (
                <Typography key={index}>{`${song.position}. ${song.songTitle} - ${song.artiste}`}</Typography>
              ))
            : 'No songs listed'}
        </Box>
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.row.date).toLocaleDateString('en-GB');
        return <Typography>{date}</Typography>;
      }
    },
    {
      field: 'creator',
      headerName: 'Creator',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenDialog(params.row)} color="error">
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box m="15px">
      <Helmet>
        <title>Charts | Cubana Charts</title>
      </Helmet>
      <Header title="CHARTS" subtitle="List of Charts" />

      <Box borderRadius="3px" sx={{ border: '1px solid #ccc' }} padding="10px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="15px">
          {/* Search Bar */}
          <Box
            display="flex"
            backgroundColor={'#e0e0e0'}
            borderRadius="3px"
            border={'1px solid #6c757d'}
            sx={{ width: '300px' }}
          >
            <InputBase
              sx={{ ml: 3, flex: 1, color: "#000" }}
              placeholder="Search Charts"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddchartIcon />}
            onClick={() => navigate('/new_chart_form')}
            sx={{ ml: 2 }}
          >
            Create New Chart
          </Button>
        </Box>
        <Box
          m="30px 0 0 0"
          height="75vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
              backgroundColor: colors.grey[700],
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
              display: 'block',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              color: colors.black[300],
              fontWeight: 'bold',
            },
            '& .name-column--cell': {
              color: colors.black[300],
              fontWeight: 'bold',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.grey[700],
              borderBottom: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.grey[100],
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.grey[500],
            },
          }}
        >
          {successMessage && <Alert severity="success" onClose={() => setSuccessMessage('')}>{successMessage}</Alert>}
          {errorMessage && <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>}
          <DataGrid
            rows={filteredCharts}
            columns={columns}
            getRowHeight={() => 'auto'}
            loading={loading}
            components={{
              NoRowsOverlay: () => (
                <Typography variant="body2" sx={{ p: 2 }}>
                  {loading ? "Loading..." : "No charts"}
                </Typography>
              ),
            }}
          />
        </Box>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this chart? Please enter your phone number to confirm.
          </DialogContentText>
          {dialogErrorMessage && (
            <Alert severity="error" onClose={() => setDialogErrorMessage('')}>
              {dialogErrorMessage}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Phone Number"
            type="text"
            required
            fullWidth
            value={phoneInput}
            onChange={(e) => setPhoneInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Charts;
