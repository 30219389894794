import React, { useState, useMemo } from 'react';
import { Box, Button, TextField, Typography, useTheme, Dialog, DialogActions, DialogContent,
   DialogContentText, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import SendIcon from '@mui/icons-material/Send';
import Header from '../../components/Header';
import { Helmet } from 'react-helmet';
import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { tokens } from '../../theme'; 

const initialValues = {
  title: '',
  artiste: '',
  country: { value: 'NG', label: 'Nigeria' }, // Default country
  ftartiste: '',
  songUrl: '',
  imageUrl: '',
};

const songSchema = yup.object().shape({
  title: yup.string().required('required'),
  artiste: yup.string().required('required'),
  ftartiste: yup.string(),
  country: yup.object().required('required').nullable(),
  songUrl: yup.string().url('Invalid URL').required('required'),
  imageUrl: yup.string().url('Invalid URL').required('required'),
});

// Utility function to capitalize the first letter of each word
const capitalize = (text) => {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
};

const New_Song_Form = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const handleClose = () => {
    setOpenModal(false);
  };

  const formSubmitHandler = async (values, { resetForm }) => {
    try {
      const formattedValues = {
        ...values,
        title: capitalize(values.title),
        artiste: capitalize(values.artiste),
        ftartiste: capitalize(values.ftartiste),
        country: values.country.label,
      };
      await addDoc(collection(db, 'Songs'), formattedValues);
      setSuccessMessage("Song added successfully");
      setErrorMessage('');
      setOpenModal(true);
      resetForm();
    } catch (e) {
      setErrorMessage("Error adding song: " + e.message);
      setSuccessMessage('');
      setOpenModal(true);
    }
  };

  return (
    <Box m="15px">
      <Helmet>
        <title>Add New Song | Cubana Admin</title>
      </Helmet>
      <Header title="ADD NEW SONG" subtitle="Add a New Song" />

      <Formik
        onSubmit={formSubmitHandler}
        initialValues={initialValues}
        validationSchema={songSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              backgroundColor="#fff"
              border="1px solid #ccc"
              padding="20px"
              borderRadius="5px"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Song Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                sx={{
                  gridColumn: 'span 2',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Artiste"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.artiste}
                name="artiste"
                error={!!touched.artiste && !!errors.artiste}
                helperText={touched.artiste && errors.artiste}
                sx={{
                  gridColumn: 'span 2',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Featured Artiste(s)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ftartiste}
                name="ftartiste"
                error={!!touched.ftartiste && !!errors.ftartiste}
                helperText={touched.ftartiste && errors.ftartiste}
                sx={{
                  gridColumn: 'span 4',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Song Link"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.songUrl}
                name="songUrl"
                error={!!touched.songUrl && !!errors.songUrl}
                helperText={touched.songUrl && errors.songUrl}
                sx={{
                  gridColumn: 'span 4',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Image Link"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.imageUrl}
                name="imageUrl"
                error={!!touched.imageUrl && !!errors.imageUrl}
                helperText={touched.imageUrl && errors.imageUrl}
                sx={{
                  gridColumn: 'span 4',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <Box sx={{ gridColumn: 'span 4', backgroundColor: colors.red, borderRadius: '4px' }}>
                <Typography variant="body1" sx={{ fontSize: 'small', color: 'black', marginBottom: '1px' }}>
                  Country
                </Typography>
                <Select
                  options={options}
                  value={values.country}
                  onChange={option => setFieldValue('country', option)}
                  onBlur={handleBlur}
                  name="country"
                  styles={{
                    control: (base) => ({
                      ...base,
                      backgroundColor: colors.red,
                      borderRadius: '0px',
                      borderColor: errors.country && touched.country ? 'red' : base.borderColor,
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '4px',
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? 'white' : colors.grey[400],
                      color: 'black',
                    }),
                  }}
                />
                {touched.country && errors.country && (
                  <div style={{ color: 'red', marginTop: '5px' }}>{errors.country}</div>
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="15px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SendIcon />}
              >
                Add Song
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Dialog
        open={openModal}
        onClose={handleClose}
      >
        <DialogTitle>{successMessage ? 'Success' : 'Error'}</DialogTitle>
        <DialogContent>
        <DialogContentText style={{ color: '#fffff' }}>
            {successMessage || errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ backgroundColor: '#3ed791' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default New_Song_Form;
