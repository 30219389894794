import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TrafficIcon from '@mui/icons-material/Traffic';
import StatBox from '../../components/StatBox';
import { Helmet } from 'react-helmet';
import { getCurrentWeekOfYear, getYearProgress } from '../../utils/dateUtils';
import { fetchChartsData, fetchChartsCount } from '../../api/firestore/chartData';
import { fetchSongsAndCharts } from '../../api/firestore/songData';
import { fetchUserData } from '../../api/firestore/userData';
import LibraryMusicOutlined from '@mui/icons-material/LibraryMusicOutlined';

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentWeek] = useState(getCurrentWeekOfYear());
  const [yearProgress, setYearProgress] = useState(getYearProgress());
  const [chartsCount, setChartsCount] = useState(0);
  const [totalSongs, setTotalSongs] = useState(0);
  const [signedUpUsers, setSignedUpUsers] = useState(0);
  const [weeklyTraffic, setWeeklyTraffic] = useState(null);
  const [chart1, setChart1] = useState(null);
  const [chart2, setChart2] = useState(null);
  const [chart3, setChart3] = useState(null);
  const [chart4, setChart4] = useState(null);
  const [chart5, setChart5] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setYearProgress(getYearProgress());
    }, 60000); // Update progress every minute

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const count = await fetchChartsCount();
        setChartsCount(count);
      } catch (error) {
      }
    };

    fetchCount();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const chartsData = await fetchChartsData(5); // Limit the fetch to 5 charts
        const [chart1, chart2, chart3, chart4, chart5] = chartsData.slice(0, 5); // Extract the first 5 charts

        setChart1(chart1);
        setChart2(chart2);
        setChart3(chart3);
        setChart4(chart4);
        setChart5(chart5);
      } catch (error) {
       
      }

      try {
        const { totalSongs } = await fetchSongsAndCharts();
        setTotalSongs(totalSongs);
      } catch (error) {
       
      }
      try {
        const { numberOfUsers } = await fetchUserData(); // Destructure the returned data
        setSignedUpUsers(numberOfUsers); // Only use the numberOfUsers for now
      } catch (error) {
        //console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box m="15px">
      <Helmet>
        <title>Admin Dashboard | Cubana Chart</title>
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="ADMIN DASHBOARD" color="black" />
      
      </Box>

      {/* Grid & Charts */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="120px"
        gap="15px"
        overflow="auto"
        padding="10px"
        borderRadius="3px"
        sx={{ border: '1px solid #ccc' }}
      >
        {/* Row 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.grey[100]}
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
        >
          <StatBox
            title={currentWeek}
            subtitle="Year Progress"
            progress={yearProgress}
            increase={`${(yearProgress * 100).toFixed(2)}%`}
            icon={
              <CalendarMonthIcon
                sx={{ color: colors.green[600], fontSize: '26px' }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.grey[100]}
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
        >
          <Box>
            <TableChartOutlinedIcon
              sx={{ color: colors.green[600], fontSize: '26px' }}
            />
            <Typography sx={{ color: '#000000', fontWeight: 'bold' }}>
              {chartsCount} Charts
            </Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.grey[100]}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <LibraryMusicOutlined
              sx={{ color: colors.green[600], fontSize: '26px' }}
            />
            <Link to="/songs-url" style={{ textDecoration: 'none', color: 'inherit' }}></Link>
            <Typography sx={{ color: '#000000', fontWeight: 'bold' }}>
              {totalSongs} Songs
            </Typography>
          </Box>
        </Box>

        <Box
          gridColumn="span 2"
          backgroundColor={colors.grey[100]}
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
        >
          <Box>
            <PersonAddIcon
              sx={{ color: colors.green[600], fontSize: '26px' }}
            />
            <Typography sx={{ color: '#000000', fontWeight: 'bold' }}>
              {signedUpUsers} Signed Up Users
            </Typography>
          </Box>
        </Box>

        <Box
          gridColumn="span 2"
          backgroundColor={colors.grey[100]}
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
        >
          <Box>
            <TrafficIcon
              sx={{ color: colors.green[600], fontSize: '26px' }}
            />
            <Typography sx={{ color: '#000000', fontWeight: 'bold' }}>
              {weeklyTraffic}
              Weekly Traffic
            </Typography>
          </Box>
        </Box>

        {/* Row 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.grey[100]}
          width="100%"
         // height= "fit-content"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
          overflow="auto"
        >
          <Box
            mt="15px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h5" fontWeight="600" color={colors.black[100]}>
                Current Week Chart - {chart1 && chart1.chartTitle}
              </Typography>
            </Box>
          </Box>

          <Box maxheight="400px" mt="10px" width="100%" 
    overflowY="auto" >
            {chart1 && (
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600] }}>Position</th>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600] }}>Songs</th>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600] }}>Artist</th>
                  </tr>
                </thead>
                <tbody>
                  {chart1.songs.map((song, index) => (
                    <tr key={index}>
                      <td style={{ color: colors.black[100] }}>{song.position}</td>
                      <td style={{ color: colors.black[100] }}>{song.songTitle}</td>
                      <td style={{ color: colors.black[100] }}>{song.artiste}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Box>
        </Box>
         {/* last week chart */}
         <Box
          gridColumn="span 4"
          gridRow="span 2"
           backgroundColor={colors.grey[100]}
           overflow="auto"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)" 
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color={colors.black[100]}
            p="10px"
          >
            <Typography color={colors.black[100]} variant="h5" fontWeight="600">
              Last Week's Chart - {chart2 && chart2.chartTitle}
            </Typography>
          </Box>
         
            <Box>
            {chart2 && (
              <table style={{ width: '100%' }}>
                <thead>
                <tr>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600]}}>Pos</th>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600] }}>Songs</th>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600] }}>Artist</th>
                  </tr>
                </thead>
                <tbody>
                  {chart2.songs.map((song, index) => (
                    <tr key={index}>
                      <td style={{color: colors.black[100]}}>{song.position}</td>
                      <td style={{paddingRight:"10px", color: colors.black[100]}}>{song.songTitle}</td>
                      <td style={{color: colors.black[100]}}>{song.artiste}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
             
            </Box>
          
        </Box>

        {/* third chart */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.grey[100]}
          overflow="auto"
          p="20px"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)" 
        >
          <Typography variant="h5" fontWeight="600" sx={{ color: '#000000' }}>
          {chart3 && chart3.chartTitle}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="15px"
          >
            {chart3 && (
              <table style={{ width: '100%' }}>
                <thead>
                <tr>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600]}}>Pos</th>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600] }}>Songs</th>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600] }}>Artist</th>
                  </tr>
                </thead>
                <tbody>
                  {chart3.songs.map((song, index) => (
                    <tr key={index}>
                      <td style={{color: colors.black[100]}}>{song.position}</td>
                      <td style={{color: colors.black[100]}}>{song.songTitle}</td>
                      <td style={{color: colors.black[100]}}>{song.artiste}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Box>
        </Box>

        {/* Chart 4 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.grey[100]}
          overflow="auto"
          p="20px"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)" 
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ color: '#000000' }}
          >
          {chart4 && chart4.chartTitle}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="15px"
          >
          
          {chart4 && (
              <table style={{ width: '100%' }}>
                <thead>
                <tr>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600]}}>Pos</th>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600] }}>Songs</th>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600] }}>Artist</th>
                  </tr>
                </thead>
                <tbody>
                  {chart4.songs.map((song, index) => (
                    <tr key={index}>
                      <td style={{color: colors.black[100]}}>{song.position}</td>
                      <td style={{color: colors.black[100]}}>{song.songTitle}</td>
                      <td style={{color: colors.black[100]}}>{song.artiste}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Box>
        </Box>

        {/* chart 5 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
           backgroundColor={colors.grey[100]}
           overflow="auto"
          p="20px"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)" 
        >
          <Typography variant="h5" fontWeight="600" sx={{ mb: '10px', color: '#000000' }}>
          {chart5 && chart5.chartTitle}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="15px"
          >
          {chart5 && (
              <table style={{ width: '100%' }}>
                <thead>
                <tr>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600]}}>Pos</th>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600] }}>Songs</th>
                    <th style={{ borderBottom: '1px solid #6c757d', color: colors.black[600] }}>Artist</th>
                  </tr>
                </thead>
                <tbody>
                  {chart5.songs.map((song, index) => (
                    <tr key={index}>
                      <td style={{color: colors.black[100]}}>{song.position}</td>
                      <td style={{color: colors.black[100]}}>{song.songTitle}</td>
                      <td style={{color: colors.black[100]}}>{song.artiste}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
