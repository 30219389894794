import React from 'react';
import { useDrag } from 'react-dnd';

const DragItem = ({ id, name, songUrl, imageUrl, isDraggable }) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'ITEM',
    item: { id, name, songUrl, imageUrl },
    canDrag: isDraggable,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={isDraggable ? drag : preview}
      style={{
        opacity: isDraggable ? (isDragging ? 0.5 : 1) : 0.5,
        cursor: isDraggable ? 'move' : 'not-allowed',
        padding: '8px',
        border: '1px solid #ccc',
        marginBottom: '4px',
        borderRadius: '4px',
        //backgroundColor: 'white',
      }}
    >
      {name}
    </div>
  );
};

export default DragItem;
