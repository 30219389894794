import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

// Fetch ads
export const fetchAds = async () => {
  try {
    // Fetch ads data
    const adsSnapshot = await getDocs(collection(db, 'Ads'));
    const adsData = adsSnapshot.docs.map((doc, index) => ({ id: index + 1, docId: doc.id, ...doc.data() }));
   
    // Count number of ads
    const numberOfAds = adsData.length;

    // Fetch ads
    const ads = adsData.map(ad => ({
      title: ad.title,
      source: ad.source,
      type: ad.type,
      adUrl: ad.adUrl,
      admin: ad.admin,
      date: ad.date,
      status: ad.status,
      docId: ad.docId,
    }));

    return {
      numberOfAds,
      ads,
    };

  } catch (error) {
    console.error('Error fetching ads:', error);
    throw error;
  }
};

// Delete ad by document ID
export const deleteAd = async (docId) => {
  try {
    await deleteDoc(doc(db, 'Ads', docId));
    console.log(`Ad with docId ${docId} deleted successfully.`);
  } catch (error) {
    console.error('Error deleting ad:', error);
    throw error;
  }
};
