import React, { useEffect, useState } from 'react';
import { Box, useTheme, CircularProgress, InputBase, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Alert } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import LyricsIcon from '@mui/icons-material/Lyrics';
import { useNavigate } from 'react-router-dom';
import { fetchSongsAndCharts, deleteSong } from '../../api/firestore/songData';

const Songs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [songs, setSongs] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [songToDelete, setSongToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { mergedData } = await fetchSongsAndCharts();
        
        // Ensure each item has a unique 'id' field
        const dataWithId = mergedData.map(song => ({
          ...song,
          id: song.docId // Ensure 'id' is unique
        }));
        
        setSongs(dataWithId);
        setFilteredSongs(dataWithId);
      } catch (error) {
        setErrorMessage('Failed to fetch songs');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = songs.filter(song =>
      song.title.toLowerCase().includes(query) ||
      song.artiste.toLowerCase().includes(query) ||
      (song.ftartiste && song.ftartiste.toLowerCase().includes(query))
    );

    setFilteredSongs(filtered);
  };

  const handleOpenDialog = (song) => {
    setSongToDelete(song);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSongToDelete(null);
  };

  const handleDelete = async () => {
    try {
      if (songToDelete) {
        await deleteSong(songToDelete.docId);

        const updatedSongs = songs.filter(song => song.docId !== songToDelete.docId);
        setSongs(updatedSongs);
        setFilteredSongs(updatedSongs);

        setSuccessMessage('Song deleted successfully');
      }
    } catch (error) {
      setErrorMessage('Failed to delete song');
    } finally {
      handleCloseDialog();
      setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 6000);
    }
  };

  const columns = [
    { field: 'title', headerName: 'Title', flex: 1, cellClassName: 'name-column--cell' },
    { field: 'artiste', headerName: 'Artiste', headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'ftartiste', headerName: 'Ft. Artiste', headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'peakPos', headerName: 'Peak Pos', type: 'number', flex: 0.5 },
    { field: 'no_of_weeks', headerName: 'Weeks on Chart', type: 'number', flex: 1 },
    { field: 'likes', headerName: 'Likes', type: 'number', flex: 0.5 },
    {
      field: 'actions',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenDialog(params.row)} color="error">
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box m="15px">
      <Helmet>
        <title>Songs | Cubana Chart</title>
      </Helmet>
      <Header title="SONGS" subtitle="List of Songs"/>
      <Box borderRadius="3px" sx={{ border: '1px solid #ccc' }} padding="10px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="15px">
          <Box
            display="flex"
            backgroundColor={'#e0e0e0'}
            borderRadius="3px"
            border={'1px solid #6c757d'}
            sx={{ width: '300px' }}
          >
            <InputBase
              sx={{ ml: 3, flex: 1, color: "#000" }}
              placeholder="Search Songs"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={< LyricsIcon  />}
            onClick={() => navigate('/new_song_form')}
            sx={{ ml: 2 }}
          >
            Add New Song
          </Button>
        
        </Box>
        <Box
          m="30px 0 0 0"
          height="75vh"
          sx={{
            '& .MuiDataGrid-root': { border: 'none' },
            '& .MuiDataGrid-cell': { borderBottom: 'none', color: colors.black[300] },
            '& .name-column--cell': { color: colors.black[300] },
            '& .MuiDataGrid-columnHeaders': { backgroundColor: colors.grey[200], borderBottom: 'none' },
            '& .MuiDataGrid-virtualScroller': { backgroundColor: colors.grey[100] },
            '& .MuiDataGrid-footerContainer': { borderTop: 'none', backgroundColor: colors.grey[500] },
          }}
        >
          {successMessage && <Alert severity="success" onClose={() => setSuccessMessage('')}>{successMessage}</Alert>}
          {errorMessage && <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>}
          <DataGrid
            rows={filteredSongs}
            columns={columns}
            loading={loading}
            getRowId={(row) => row.docId} // Specify the custom ID field
            components={{
              NoRowsOverlay: () => (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  {loading ? <CircularProgress /> : "No songs"}
                </Box>
              ),
            }}
          />
        </Box>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this song?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Songs;
