import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  Modal,
  Fade,
  Backdrop,
  TextField,
  styled,
} from '@mui/material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useUserAuth } from "../../context/UserAuthContext";
import emailjs from '@emailjs/browser';

const ItemType = 'ROW';

// Utility function to capitalize all letters in a string
const capitalizeAll = (text) => {
  return text.toUpperCase();
};

// Generate a 6-digit confirmation code
const generateConfirmationCode = () => {
  return Math.floor(100000 + Math.random() * 900000).toString();
};

// Styled components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: theme.shadows[3],
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '& .MuiTableCell-head': {
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, isDragging }) => ({
  opacity: isDragging ? 0.5 : 1,
  backgroundColor: 'lightgrey',
  '&:nth-of-type(odd)': {
    backgroundColor: 'whitesmoke',
  },
  cursor: 'move',
  '& .MuiTableCell-body': {
    color: '#000',
    fontWeight: 'bold',
  },
}));

const DraggableRow = ({ song, index, moveRow }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <StyledTableRow ref={ref} isDragging={isDragging}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{song.title}</TableCell>
      <TableCell>{song.artiste}</TableCell>
    </StyledTableRow>
  );
};

const PreviewChart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [songs, setSongs] = useState([]);
  const [chartTitle, setChartTitle] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { user } = useUserAuth();
  const [confirmationCode, setConfirmationCode] = useState('');
  const [userEnteredCode, setUserEnteredCode] = useState('');
  const [isConfirmationSent, setIsConfirmationSent] = useState(false);
  const [codeTimestamp, setCodeTimestamp] = useState(null);

  useEffect(() => {
    if (location.state) {
      if (location.state.songs) {
        const songsWithDetails = location.state.songs.map((song) => {
          const [title, artiste] = song.name.split(' - ');
          return { ...song, title, artiste };
        });
        setSongs(songsWithDetails);
      }
      if (location.state.chartTitle) {
        setChartTitle(location.state.chartTitle.toUpperCase());
      }
    }
  }, [location.state]);

  const moveRow = (dragIndex, hoverIndex) => {
    const updatedSongs = [...songs];
    const [removed] = updatedSongs.splice(dragIndex, 1);
    updatedSongs.splice(hoverIndex, 0, removed);
    setSongs(updatedSongs);
  };

  // Function to send email
  const sendConfirmationEmail = async (email, code, chartTitle) => {
    const templateParams = {
      email: email,
      confirmation_code: code,
      chartTitle: chartTitle,
    };

    try {
      await emailjs.send('service_zhxxspe', 'template_22sjjks', templateParams, '41IYOD_WlubWyd4I8');
      setSnackbarMessage('Confirmation code sent successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage('Failed to send confirmation code. Please try again later.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handlePublish = async () => {
    try {
      const chartsQuery = query(collection(db, 'Charts'), where('chartTitle', '==', chartTitle));
      const querySnapshot = await getDocs(chartsQuery);

      if (!querySnapshot.empty) {
        setSnackbarMessage('Chart title already exists. Cancel and choose a different title.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        setSuccessMessage('');
        setErrorMessage('Chart title already exists. Cancel and choose a different title.');
        return;
      }

      const code = generateConfirmationCode();
      await sendConfirmationEmail(user.email, code, chartTitle);
      setConfirmationCode(code);
      setIsConfirmationSent(true);
      setCodeTimestamp(Date.now());
    } catch (error) {
      setSuccessMessage('');
      setErrorMessage('Failed to send confirmation code. Please try again later.');
    }
  };

  const handleConfirmPublish = async () => {
    const tenMinutes = 10 * 60 * 1000;
    const currentTime = Date.now();

    if (currentTime - codeTimestamp > tenMinutes) {
      setSnackbarMessage('Confirmation code has expired. Please request a new code.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setErrorMessage('Confirmation code has expired. Please request a new code.');
      return;
    }

    if (userEnteredCode !== confirmationCode) {
      setErrorMessage('Incorrect confirmation code. Please try again.');
      setSnackbarMessage('Incorrect confirmation code. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const currentDate = new Date().toISOString();
      await addDoc(collection(db, 'Charts'), {
        chartTitle: capitalizeAll(chartTitle),
        songs: songs.map((song, index) => ({
          songId: song.id,
          position: index + 1,
          songTitle: song.title,
          artiste: song.artiste,
          songUrl: song.songUrl,
          imageUrl: song.imageUrl,
        })),
        creator: user.email,
        date: currentDate,
      });
      setSuccessMessage('Chart published successfully!');
      setOpenModal(true);
    } catch (error) {
      setSuccessMessage('');
      setErrorMessage('Failed to publish chart. Please try again later.');
    }
  };

  const handleCancel = () => {
    navigate('/new_chart_form');
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate('/charts');
  };

  return (
    <Box m="15px">
      <Helmet>
        <title>Chart Preview | Cubana Chart</title>
      </Helmet>
      <Header title="PREVIEW NEW CHART" subtitle="" />
      <Box>
        <Typography variant="h4" color='#000' gutterBottom>
        {capitalizeAll(chartTitle)}
        </Typography>
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

        <DndProvider backend={HTML5Backend}>
          <StyledTableContainer component={Paper}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Position</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Artist</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {songs.map((song, index) => (
                  <DraggableRow
                    key={song.id}
                    index={index}
                    song={song}
                    moveRow={moveRow}
                  />
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </DndProvider>
        <Box display="flex" justifyContent="space-between" mt="20px">
          <Button variant="contained" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          {!isConfirmationSent ? (
            <Button variant="contained" color="primary" onClick={handlePublish}>
              Publish
            </Button>
          ) : (
            <Box>
              <TextField
                label="Enter Confirm Code"
                variant="outlined"
                InputLabelProps={{
                  style: { color: 'black', fontSize: '0.8rem' },
                }}
                InputProps={{
                  style: { color: 'black' },
                }}
                sx={{
                  width: '150px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ccc',
                    },
                    '&:hover fieldset': {
                      borderColor: 'green',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#000',
                    },
                  },
                }}
                value={userEnteredCode}
                onChange={(e) => setUserEnteredCode(e.target.value)}
              />
              <Button variant="contained" color="primary" onClick={handleConfirmPublish}>
                Confirm Publish
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              outline: 0,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Success
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Chart published successfully!
            </Typography>
            <Box mt={3} textAlign="center">
              <Button variant="contained" color="primary" onClick={handleCloseModal}>
                OK
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default PreviewChart;
