import React, { useState, useMemo } from 'react';
import { Box, Button, TextField, Typography, useTheme, Dialog, DialogActions, DialogContent,
   DialogContentText, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import SendIcon from '@mui/icons-material/Send';
import Header from '../../components/Header';
import { Helmet } from 'react-helmet';
import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import Select from 'react-select';
import { tokens } from '../../theme'; 
import { useUserAuth } from "../../context/UserAuthContext";

const initialValues = {
  title: '',
  source: '',
  type: { value: 'type', label: 'Type' }, 
  adUrl: '',
  admin:'',
  status: ''
};

const adSchema = yup.object().shape({
  title: yup.string().required('required'),
  source: yup.string().required('required'),
  type: yup.object().required('required').nullable(),
  adUrl: yup.string().url('Invalid URL').required('required'),
});

// Utility function to capitalize the first letter of each word
const capitalize = (text) => {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
};

const New_Ad_Form = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const { user } = useUserAuth();

  const handleClose = () => {
    setOpenModal(false);
  };

  const formSubmitHandler = async (values, { resetForm }) => {
    try {
      const currentDate = new Date().toISOString();
      const formattedValues = {
        ...values,
        title: capitalize(values.title),
        source: capitalize(values.source),
        type: capitalize(values.type.label),
        admin: user.email,
        date: currentDate,
        status: "not running",
      };
      await addDoc(collection(db, 'Ads'), formattedValues);
      setSuccessMessage("Ad added successfully");
      setErrorMessage('');
      setOpenModal(true);
      resetForm();
    } catch (e) {
      setErrorMessage("Error adding ad: " + e.message);
      setSuccessMessage('');
      setOpenModal(true);
    }
  };

  return (
    <Box m="15px">
      <Helmet>
        <title>Add New Ad | Cubana Admin</title>
      </Helmet>
      <Header title="ADD NEW AD" subtitle="Add a New Ad" />

      <Formik
        onSubmit={formSubmitHandler}
        initialValues={initialValues}
        validationSchema={adSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              backgroundColor="#fff"
              border="1px solid #ccc"
              padding="20px"
              borderRadius="5px"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Ad Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                sx={{
                  gridColumn: 'span 2',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Source"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.source}
                name="source"
                error={!!touched.source && !!errors.source}
                helperText={touched.source && errors.source}
                sx={{
                  gridColumn: 'span 2',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Ad Link"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.adUrl}
                name="adUrl"
                error={!!touched.adUrl && !!errors.adUrl}
                helperText={touched.adUrl && errors.adUrl}
                sx={{
                  gridColumn: 'span 4',
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: '#ccc',
                  },
                  '& .MuiFilledInput-input': {
                    color: '#000',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              
              <Box sx={{ gridColumn: 'span 4', backgroundColor: colors.red, borderRadius: '4px' }}>
                <Typography variant="body1" sx={{ fontSize: 'small', color: 'black', marginBottom: '1px' }}>
                  Type
                </Typography>
                  <Select
                    options={[
                      { value: 'ad network', label: 'Ad Network' },
                      { value: 'custom', label: 'Custom' },
                    ]}
                    value={values.type}
                    onChange={option => setFieldValue('type', option)}
                    onBlur={handleBlur}
                    name="type"
                    styles={{
                      control: (base) => ({
                        ...base,
                        backgroundColor: colors.red,
                        borderRadius: '0px',
                        borderColor: errors.type && touched.type ? 'red' : base.borderColor,
                      }),
                      menu: (base) => ({
                        ...base,
                        borderRadius: '4px',
                      }),
                      option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isFocused ? 'white' : colors.grey[400],
                        color: 'black',
                      }),
                    }}
                />
                {touched.type && errors.type && (
                  <div style={{ color: 'red', marginTop: '5px' }}>{errors.type}</div>
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="15px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SendIcon />}
              >
                Add Ad
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Dialog
        open={openModal}
        onClose={handleClose}
      >
        <DialogTitle>{successMessage ? 'Success' : 'Error'}</DialogTitle>
        <DialogContent>
        <DialogContentText style={{ color: '#fffff' }}>
            {successMessage || errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ backgroundColor: '#3ed791' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default New_Ad_Form;
