import { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import { formatDate } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
  styled,
} from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { Helmet } from 'react-helmet';
import { getCurrentWeekOfYear } from '../../utils/dateUtils';

const StyledFullCalendar = styled(FullCalendar)({
  '.fc .fc-daygrid-day.fc-day-today': {
    backgroundColor: '#dc3545',
},
  '.fc-theme-standard td, .fc-theme-standard th': {
    border: '1px solid #000',
},
  '.fc': {
    // Targeting FullCalendar's main container
    border: '10px solid black', 
  },
  '.fc th, .fc td': {
    // Targeting table cells and headers
    border: '1px solid black', 
  },
});

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentWeek] = useState(getCurrentWeekOfYear());
  const [currentEvents, setCurrentEvents] = useState([]);

  const handleDateClick = (selected) => {
    const title = prompt('Please enter title of your event');
    const calendarApi = selected.view.calendar;
    calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: `${selected.dataStr}-${title}`,
        title,
        start: selected.startStr,
        end: selected.endStr,
        allDay: selected.allDay,
      });
    }
  };

  const handleEventClick = (selected) => {
    if (
      window.confirm(
        `Are you sure you want to delete event '${selected.event.title}'`
      )
    ) {
      selected.event.remove();
    }
  };

  return (
    <Box m="15px">
      <Helmet>
        <title>Calendar | Cubana Chart Admin</title>
      </Helmet>
      <Header title="CALENDAR" subtitle={currentWeek} />

      <Box display="flex" justifyContent="space-between"
      borderRadius="3px"
      sx={{ border: '1px solid #ccc' }}
      padding="10px"
      > 
        {/* Calendar Sidebar */}
        <Box
          flex="1 1 20%"
          backgroundColor="#fff"
          p="15px"
          borderRadius="4px"
          border="1px solid #6c757d"
          color="#000"
        >
          <Typography variant="h5">Events</Typography>
          <List>
            {currentEvents.map((event) => (
              <ListItem
                key={event.id}
                sx={{
                  backgroundColor: '#e0e0e0',
                  color: colors.black[500],
                  margin: '8px 0',
                  borderRadius: '2px',
                }}
              >
                <ListItemText
                  primary={event.title}
                  secondary={
                    <Typography>
                      {formatDate(event.start, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Calendar */}
        <Box flex="1 1 100%" ml="15px" 
        backgroundColor ="#fff" color="#000" 
        border="1px solid #6c757d"
        padding={"5px"}>
          <StyledFullCalendar
            height="74vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridWeek,dayGridMonth,timeGridDay,listMonth',
            }}
            initialView="dayGridWeek"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            eventClick={handleEventClick}
            eventsSet={(events) => setCurrentEvents(events)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Calendar;
