export const getCurrentWeekOfYear = () => {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  return `Week ${Math.ceil(days / 7)} of ${currentDate.getFullYear()}`;
};

export const getYearProgress = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const end = new Date(now.getFullYear() + 1, 0, 1);
    const current = now - start;
    const total = end - start;
    return current / total;
  };