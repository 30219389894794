import React, { useState } from 'react';
import { Box, TextField, Button, Alert } from '@mui/material';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import { useUserAuth } from '../../context/UserAuthContext';
import emailjs from '@emailjs/browser';

const SAD = () => {
  const { user } = useUserAuth();
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState(user ? user.email : '');
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setAlert(''); // Reset alert

    emailjs
      .sendForm('service_zhxxspe', 'template_pbc7gfh', e.target, '41IYOD_WlubWyd4I8')
      .then(
        () => {
          // Reset form fields
          setSubject('');
          setEmail(user ? user.email : '');
          setMessage('');

          // Display success message
          setAlert('Message sent successfully.');
        },
        (error) => {
          setAlert('Failed to send the message. Please try again.');
        }
      );
  };

  return (
    <Box m="15px">
      <Helmet>
        <title>Contact Site Admin | Cubana Chart</title>
      </Helmet>
      <Header title="CONTACT SITE ADMIN" subtitle="Fill the form" />
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          mt: '20px',
          padding: '20px',
          maxWidth: '600px',
          mx: 'auto',
          backgroundColor: '#fff',
          borderRadius: '4px',
          border: '1px solid #ccc',
        }}
        onSubmit={handleSubmit}
      >
        {alert && <Alert severity="success">{alert}</Alert>}
        <TextField
          label="Subject"
          variant="outlined"
          name="subject" 
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
          InputLabelProps={{
            style: { color: 'black' },
          }}
          InputProps={{
            style: { color: 'black', borderColor: '#ccc' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#ccc',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ccc',
              },
            },
          }}
        />
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          name="email" 
          value={email}
          InputLabelProps={{
            style: { color: 'black' },
          }}
          InputProps={{
            readOnly: true,
            style: { color: 'black' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#ccc',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ccc',
              },
            },
          }}
        />
        <TextField
          label="Message"
          variant="outlined"
          multiline
          rows={4}
          name="message" 
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
          InputLabelProps={{
            style: { color: 'black' },
          }}
          InputProps={{
            style: { color: 'black' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#ccc',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ccc',
              },
            },
          }}
        />
        <Button variant="contained" color="primary" type="submit">
          Send Message
        </Button>
      </Box>
    </Box>
  );
};

export default SAD;
