import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

// Fetch admins and charts data
export const fetchAdminsAndCharts = async () => {
  try {
    // Fetch admins data
    const adminsSnapshot = await getDocs(collection(db, 'admin'));
    const adminsData = adminsSnapshot.docs.map((doc, index) => ({
      id: index + 1,
      docId: doc.id,
      ...doc.data(),
    }));

    // Fetch charts data
    const chartsSnapshot = await getDocs(collection(db, 'Charts'));
    const chartsData = chartsSnapshot.docs.map((doc) => doc.data());

    // Count the number of charts posted by each admin using their email as a reference
    const adminChartCounts = adminsData.map((admin) => {
      const chartCount = chartsData.filter((chart) => chart.creator === admin.email).length;
      return {
        ...admin,
        chartCount,
      };
    });

    return adminChartCounts;
  } catch (error) {
    throw error;
  }
};

// Delete admin by document ID
export const deleteAdmin = async (docId) => {
  try {
    await deleteDoc(doc(db, 'admin', docId));
  } catch (error) {
    throw error;
  }
};
