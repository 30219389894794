import React from "react";


const Unauthorized = () => {
  
  return (
    <>
      <div className="" >
        <div className="p-4 box">
          <div style={{ color: 'red' }}>
            <h1>Unauthorized</h1>
            <p>You do not have permission to view this page!!!</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Unauthorized;
