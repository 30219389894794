import React, { useState } from 'react'; 
import { Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { tokens } from '../../theme';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';
import LyricsIcon from '@mui/icons-material/Lyrics';
import AddchartIcon from '@mui/icons-material/Addchart';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === to}
      style={{
        color: colors.black[100],
        backgroundColor: selected === to ? '#ada9a9' : 'transparent',
      }}
      onClick={() => setSelected(to)}
      icon={icon}
    >
      <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Typography>{title}</Typography>
      </Link>
    </MenuItem>
  );
};

const Sidebar = ({ currentPath }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(currentPath);

  const { user, userRole, logOut } = useUserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      
    }
  };

  return (
    <Box padding={'10px'}
      sx={{
        margin: '10px', 
        border: '1px solid #ccc',
        '& .pro-sidebar-inner': {
          background: `${colors.primary[400]} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: '#6e6e6e !important',
        },
        '& .pro-menu-item.active': {
          color: '#6870fa !important',
        },
      }}
    >
      <Menu iconShape="square">
        <MenuItem
          onClick={() => setIsCollapsed(!isCollapsed)}
          icon={isCollapsed ? <MenuOutlinedIcon sx={{ color: 'black' }} /> : undefined}
          style={{
            margin: '0 0 15px 0',
            color: colors.black[100],
          }}
        >
          {!isCollapsed && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              ml="15px"
              style={{ color: colors.green[100] }}
            >
              <Typography variant="h3" color={colors.black[100]}>
                Cubana Chart Admin
              </Typography>
              <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                <MenuOutlinedIcon sx={{ color: 'black' }} />
              </IconButton>
            </Box>
          )}
        </MenuItem>

        {!isCollapsed && (
          <Box mb="20px"  backgroundColor="#e0e0e0">
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src={`../../logo.png`}
                alt="user-profile"
                width="100px"
                height="100px"
                style={{ borderRadius: '50%', cursor: 'pointer' }}
              />
            </Box>

            <Box 
            textAlign="center">
              <Typography
                variant="h3"
                color={colors.black[100]}
                fontWeight="bold"
                sx={{ m: '10px 0 0 0' }}
              >
                {user && user.email}
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
              <div className="d-grid gap-2" style={{ padding: "20px" }}>
              <Button variant="primary" size="small" onClick={handleLogout}>
                Log out
              </Button>
            </div>
              </Typography>
            </Box>
          </Box>
        )}

        <Box paddingLeft={isCollapsed ? undefined : '10%'} backgroundColor="#e0e0e0" padding="5px">
        <Box borderBottom={'1px solid #6c757d'} paddingBottom={'5px'}>  
          <Item
            title="Dashboard"
            to="/"
            icon={<HomeOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
        <Box borderBottom={'1px solid #6c757d'} paddingBottom={'5px'}>  
          <Typography
            variant="h6"
            color={colors.black[800]}
            fontSize="larger"
            fontWeight="bold"
            sx={{ m: '15px 0 5px 20px' }}
          >
            Data
          </Typography>
          <Item
            title="Artists"
            to="/artists"
            icon={<PeopleOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Songs"
            to="/songs"
            icon={<LibraryMusicOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Charts"
            to="/charts"
            icon={<TableChartOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
        <Box borderBottom={'1px solid #6c757d'} paddingBottom={'5px'}>
          <Typography
            variant="h6"
            color={colors.black[800]}
            fontSize="larger"
            fontWeight="bold"
            sx={{ m: '15px 0 5px 20px' }}
          >
            Pages
          </Typography>
          <Item
            title="Create New Chart"
            to="/new_chart_form"
            icon={<AddchartIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Add New Song"
            to="/new_song_form"
            icon={<LyricsIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Calendar"
            to="/calendar"
            icon={<CalendarTodayOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Contact Site Admin"
            to="/contact_site_admin"
            icon={<HelpOutlineOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          </Box>
          {/* Conditionally render Admin section */}
          {(userRole === "Super Admin" || userRole === "Site Admin") && (
          <Box borderBottom={'1px solid #6c757d'} paddingBottom={'5px'}>
          <Typography
            variant="h6"
            color={colors.black[800]}
            fontSize="larger"
            fontWeight="bold"
            sx={{ m: '15px 0 5px 20px' }}
          >
            Admin
          </Typography>
          <Item
            title="Admins"
            to="/admin"
            icon={<SupervisorAccountIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Add New Admin"
            to="/add_new_admin"
            icon={<AdminPanelSettingsIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Ads"
            to="/ads"
            icon={<BorderClearIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Create New Ads"
            to="/create_new_ad"
            icon={<BorderBottomIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          </Box>
          )}
        </Box>
      </Menu>
    </Box>
  );
};

export default Sidebar;
