import React from 'react';
import { useDrop } from 'react-dnd';

const DropZone = ({ onDrop }) => {
  const [, drop] = useDrop({
    accept: 'ITEM',
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        border: '2px dashed #ccc',
        padding: '16px',
        minHeight: '100px',
       // backgroundColor: isOver ? 'lightyellow' : 'white',
      }}
    >
      Drop items here
    </div>
  );
};

export default DropZone;
